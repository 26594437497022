import React from "react"
import siteLangData from "~data/languages.yml"
import FrontmatterPageContext from "~context/FakePageContext"
import {
  LanguageSelector,
  LanguageSelectorTrigger,
  LanguageSelectorList,
  LanguageSelectorItem,
} from "@fastly/consistently"
import { GlobeC } from "@fastly/consistently-icons"

const LanguageDropdown = (props) => {
  const page = React.useContext(FrontmatterPageContext)

  if (typeof page.i18n === "undefined" || page.i18n === null || page.i18n.length === 0) {
    return <></>
  }

  const { pathname } = props.location
  const options = []

  // loop over all the system languages
  for (const [langCode, langMeta] of Object.entries(siteLangData)) {

    let langUrl = pathname

    //   for each one, if either the current page is that language, or it is in the page.frontmatter.i18n array, create a menu option for it

    if (langCode == page.lang) {
      options.push({
        id: langMeta.id,
        name: langMeta.lname,
        languageCode: langMeta.iso,
        href: langUrl,
      })
      continue
    }

    //   in the case of the current page, use the current page's pathname (via the props.location), other wise calculate it
    if (page?.i18n.includes(langCode)) {
      // the loop iteration is IN the page's "link to other languages explicit list", so make a link!

      //     in the case of calculating it, first check page.frontmatter.i18n_path for the same language key, use it first;
      if (page?.i18n_path?.[langCode]) {
        langUrl = page.i18n_path[langCode]
      } else {
        //     other wise calculate the url based on the page.frontmatter.section
        switch (page?.section) {
          case "guides":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "fundamentals":
            langUrl = `/${langMeta.iso}/fundamentals/${page.fileslug}`
            break
          case "products":
            langUrl = `/products/${page.fileslug}`
            break
          default:
            // note, this should never get hit
            console.error(
              "hit the default case on page.section in langDropdown for lang[%s] and section[%s]",
              langCode,
              page.section
            )
        }
        // console.log('calculated langUrl for %s to be "%s"', langCode, langUrl)
      }

      options.push({
        id: langMeta.id,
        name: langMeta.lname,
        languageCode: langMeta.iso,
        href: langUrl,
      })
    }

  }

  const handleSelect = (clickedOption) => {
    window.location = clickedOption.href
  }

  return (
    <LanguageSelector defaultSelected={page.lang}>
      <LanguageSelectorTrigger
        icon={<GlobeC color="transparent" width="15px" title="GlobeLogo" titleId="GlobeLogo" role="img" />}
      />
      <LanguageSelectorList>
        {options.map((item, index) => {
          return (
            <LanguageSelectorItem
              data={item}
              onSelect={() => handleSelect(item)}
              index={index}
              key={item.languageCode}
            />
          )
        })}
      </LanguageSelectorList>
    </LanguageSelector>
  )
}

export default LanguageDropdown
