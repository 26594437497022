import React from "react"

import LayoutCore from "~templates/layout-core"
import { mainArea, main } from "~vanilla/layout-default.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import FrontmatterPageContext from "~context/FakePageContext"
import LanguageDropdown from "~components/lang-dropdown"
import { BreadcrumbsPrimary } from "~components/Breadcrumbs/Breadcrumbs"
import { SideMenuSwitcher } from "~components/SideMenu/side-menu"

const LayoutSectionIndex = (props ) => {
  const frontmatter = React.useContext(FrontmatterPageContext)
  const { children, location } = props

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <div
              className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
              role="navigation"
              aria-label="Navigation between documents"
              data-swiftype-index="false"
            >
              <SideMenuSwitcher />
            </div>

            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-crumb">
                <BreadcrumbsPrimary />
              </div>

              <div className="content-lang">
                <LanguageDropdown  location={location} />
              </div>

              <div className="content-content" data-from="section_index">
                {children}
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default LayoutSectionIndex
